<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterDepartments">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <app-input v-model="filter.name" id="filter_name" :label="$t('department.name')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('department.id')"></app-input>
            </div>
            <div class="col-lg-4">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('department.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import DepartmentFilter from '../../model/DepartmentFilter'

export default {
  name: 'DepartmentFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(DepartmentFilter)
    }
  },
  components: {
    appInput: Input
  },
  methods: {
    filterDepartments () {
      this.$store.commit('department/setPage', 1)
      this.$store.commit('department/setFilter', this.filter)
      this.$store.dispatch('department/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(DepartmentFilter)
      this.$store.commit('department/setFilter', this.filter)
      this.$store.dispatch('department/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['department/filter']
  }
}
</script>

<style lang="scss">

</style>
